var currentPath = window.location.pathname;

const variants = {
  'fbm': 'https://apps.apple.com/app/apple-store/id1659761499?pt=125837562&ct=facebook_messages&mt=8',
  'fbp': 'https://apps.apple.com/app/apple-store/id1659761499?pt=125837562&ct=facebook_posts&mt=8',
  'tm': 'https://apps.apple.com/app/apple-store/id1659761499?pt=125837562&ct=telegram_messages&mt=8',
  'tai': 'https://apps.apple.com/app/apple-store/id1659761499?pt=125837562&ct=telegram_ads_internal&mt=8'
};

let didRedirect = false;
for (const key in variants) {
  const value = variants[key];

  if (currentPath.includes(key)) {
    setTimeout(function () {
      window.location.href = value;
      didRedirect = true;
    }, 2000)
  }
}

if (didRedirect === false) {
  window.location.href = 'https://apps.apple.com/app/apple-store/id1659761499';
}
